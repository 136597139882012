import React from 'react';
import { Box, Grid, Typography, Link, TextField, Button, IconButton, Divider, useMediaQuery } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";


function Footer() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  return (
    <Box sx={{ bgcolor: '#0a074f', color: 'white', py: 4, px: isSmallScreen ? 4 : 10 }}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <Box sx={{ marginRight: "2%", mb: 0.5 }}>
            <img src={Logo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography variant="body2" sx={{ color: "#8E96AB" }}>
            The first platform where the job seeker looks for talent.</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Useful Links</Typography>
          <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, color: "#8E96AB", textDecoration: "none" }}>Home</Link>
          <Box sx={{ display: 'block', mb: 1, color: "#8E96AB", textDecoration: "none" }}>
            <Link href="/login" color="inherit" sx={{ textDecoration: "none", color: "#8E96AB" }}>
              Login
            </Link>
            {" / "}
            <Link href="/register" color="inherit" sx={{ textDecoration: "none", color: "#8E96AB" }}>
              Register
            </Link>
          </Box>
          <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, color: "#8E96AB", textDecoration: "none" }}>Terms of conditions</Link>
          <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, color: "#8E96AB", textDecoration: "none" }}>Privacy Policy</Link>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Get job notifications</Typography>
          <Typography variant="body2" sx={{ color: "#8E96AB" }}>
            <Typography variant="body2" sx={{ color: "#8E96AB" }}>
              The latest job news, articles, sent to your.
            </Typography>inbox weekly.
          </Typography>
          <Grid xs={12} >
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                alignItems: isSmallScreen ? 'stretch' : 'center',
                gap: 1 // This adds space between the elements
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Email Address"
                sx={{
                  flex: 1,
                  mr: isSmallScreen ? 0 : 1,
                  mb: isSmallScreen ? 1 : 0,
                  bgcolor: 'white',
                  borderRadius: "10px",
                  justifyContent: "center"
                }}
              />
              <Button
                variant="contained"
                sx={{
                  bgcolor: '#8EFFC2',
                  color: '#031141',
                  textTransform: 'none',
                  height: "55px",
                  borderRadius: "10px",
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#8EFFC2'
                  }
                }}
              >
                Subscribe
              </Button>
            </Box>
          </Grid>

        </Grid>
      </Grid>
      <Divider sx={{ mt: 5, borderColor: "#062F53" }} />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, justifyContent: "space-between" }}>
        <Typography variant="caption" sx={{ display: 'block', mt: 2, color: "#8E96AB", }}>
          © 2024 Hírio. All rights reserved.
        </Typography>
        <Box>
          <IconButton color="inherit"
            onClick={() =>
              window.open("https://www.facebook.com/people/Hirio/61568307422187/", "_blank")
            }
          ><FacebookIcon /></IconButton>
          <IconButton color="inherit" onClick={() =>
            window.open("https://www.instagram.com/hirio.app/", "_blank")
          }><InstagramIcon /></IconButton>
          <IconButton color="inherit" onClick={() =>
            window.open("https://www.linkedin.com/company/hirio-app/", "_blank")
          }><LinkedInIcon /></IconButton>
          <IconButton color="inherit" onClick={() =>
            window.open("https://x.com/HirioApp", "_blank")
          }><TwitterIcon /></IconButton>
        </Box>

      </Box>
    </Box>
  );
}

export default Footer;
