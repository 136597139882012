import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { JobPostApi, OnBoardingApi } from "../../apis";
import UploadImage from "../GlobalComponent/UploadImage";
import AddIcon from "@mui/icons-material/Add";
import { UploadImageApi } from "../../apis";
import Loader from "../GlobalComponent/Loader";
import data from "../JobPost/data.json";
import ExperienceForm from "../GlobalComponent/EditExperienceForm";
import DynamicAutocomplete from '../GlobalComponent/CityFinder';

const validationSchema = yup.object({});

const JobDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openPersonalInfo, setOpenPersonalInfo] = useState(false);
  const [languages, setLanguages] = useState([{ language: "", level: "" }]);
  const [addedLanguages, setAddedLanguages] = useState([false]);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState('');

  const [showAutocomplete, setShowAutocomplete] = useState(false);

  // Handle change to update location and display DynamicAutocomplete if "onsite" or "hybrid"
  const handleLocationChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("location", value);

    // Show DynamicAutocomplete if location is "onsite" or "hybrid"
    setShowAutocomplete(value === "onsite" || value === "hybrid");
  };
  useEffect(() => {
    setLoading(true);
    JobPostApi.getJobPostById(id)
      .then((res) => {
        const jobPost = res.data.data;

        if (jobPost) {
          const parsedJobPost = {
            ...jobPost,
            jobDetails: parseJSON(jobPost.jobDetails),
            requirements: parseJSON(jobPost.requirements),
            description: parseJSON(jobPost.description),
            company_profile_photo_url:
              jobPost.Profile.company_profile_photo_url,
          };
          setJobData(parsedJobPost);

        } else {
          console.error(`Job post with ID ${id} not found.`);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job post:", error);
        setLoading(false);
      });
  }, [id]);

  const subcategories = data.subcategories;

  const professionalArea = jobData?.jobDetails?.specialties;

  // Ensure professionalArea is an array before accessing the first element
  const singleCategory = Array.isArray(professionalArea) ? professionalArea[0] : undefined;


  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleSpecialityCheckboxChange = (event) => {
    const { name, checked } = event.target;

    formik.setFieldValue(`specialties.${name}`, checked);
    setSelectedCategories((prev) => {
      const newSelected = { ...prev };
      if (checked) {
        newSelected[name] = [];
      } else {
        delete newSelected[name];
      }
      return newSelected;
    });
  };
  const specialtiesList = data.specialties || []; // Extract specialties from data.json
  const experienceList = data.experienceLevels || []; // Extract experience levels from data.json

  const specialtiesObject = specialtiesList.reduce((acc, specialty) => {
    acc[specialty] = true; // or any relevant details
    return acc;
  }, {});


  // Helper function to safely parse JSON
  const parseJSON = (jsonString) => {
    try {
      // Check if jsonString is already an object
      if (typeof jsonString === "object") {
        return jsonString; // Return as-is if already an object
      }
      return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {}; // Return empty object if parsing fails
    }
  };
  let user;
  try {
    const userString = localStorage.getItem("user");
    user = JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing user from localStorage:", error);
  }

  const isEmployee = user && user?.role?.name === "employee";
  const isRecruiter = user && user?.role?.name === "recruiter";
  const handleInputSalaryChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value.replace(/,/g, ''); // Remove existing commas
    // Add commas to the number
    formik.setFieldValue(name, formattedValue);
};

  const handleInputChange = (event) => {
    const { name, checked } = event.target;
    formik.setFieldValue(`specialties.${name}`, checked);
  };

  const handleExperienceChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(`jobDetails.experienceYears.${name}`, value);
  };

  const handleTotalExperienceChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const handleProfilePhotoUpload = async () => {
    if (profilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", profilePhoto);

        const res = await UploadImageApi.uploadImage(formData);

        formik.setFieldValue("photo", res.data.data.name);
        return res.data.data.name; // Return only the name of the uploaded photo
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
    return null;
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const [field, subfield] = name.split(".");
    const otherSubfield = subfield === "partTime" ? "fullTime" : "partTime";

    formik.setFieldValue(`${field}.${subfield}`, checked);
    if (checked) {
      formik.setFieldValue(`${field}.${otherSubfield}`, false);
    }
  };
// Handle Specialty Selection from Dropdown
const handleSpecialtyChange = (event) => {
  const specialty = event.target.value;
  setSelectedSpecialty(specialty);

  // Clear all previous specialties and set the new one to true
  const updatedSpecialities = Object.keys(formik.values.specialties).reduce((acc, key) => {
      acc[key] = false; // Reset all previous specialties to false
      return acc;
  }, {});

  // Update Formik's specialties state
  updatedSpecialities[specialty] = true; // Set the newly selected specialty to true

  formik.setFieldValue(`specialties`, updatedSpecialities);
};

// Handle Subcategory Chip Click
const handleChipClick = (subcategory) => {
  // Get the current experience years for the selected specialty
  const currentExperience = formik.values.experienceYears[selectedSpecialty] || {}; // Fixed access

  // Toggle the clicked subcategory experience
  const updatedExperience = {
      ...currentExperience,
      [subcategory]: !currentExperience[subcategory], // Toggle the current value
  };

  // Update Formik's subcategory state dynamically
  formik.setFieldValue(
      `experienceYears.${selectedSpecialty}`,
      updatedExperience
  );
};
  // // Handle Specialty Selection from Dropdown
  // const handleSpecialtyChange = (event) => {
  //   const newSpecialty = event.target.value;

  //   // Reset the previous specialty's chips (if any)
  //   if (selectedSpecialty) {
  //     Object.keys(formik.values.experienceYears[selectedSpecialty] || {}).forEach((subcategory) => {
  //       formik.setFieldValue(`experienceYears.${selectedSpecialty}.${subcategory}`, false);
  //     });
  //   }

  //   // Set the new specialty
  //   setSelectedSpecialty(newSpecialty);

  //   // Update Formik's specialties state
  //   formik.setFieldValue(`specialties.${newSpecialty}`, true);
  // };

  // // Handle Subcategory Chip Click
  // const handleChipClick = (subcategory) => {
  //   const updatedExperience = !formik.values.experienceYears[selectedSpecialty]?.[subcategory];

  //   // Update Formik's subcategory state dynamically
  //   formik.setFieldValue(
  //     `experienceYears.${selectedSpecialty}.${subcategory}`,
  //     updatedExperience
  //   );
  // };
  const formik = useFormik({
    initialValues: {
      photo: jobData?.jobDetails?.photo || "",
      positionName: jobData?.jobDetails?.positionName || "",
      location: jobData?.jobDetails?.location || "",
      city: jobData?.jobDetails?.city || "",
      employmentType: {
        partTime: jobData?.jobDetails?.employmentType?.partTime || false,
        fullTime: jobData?.jobDetails?.employmentType?.fullTime || false,
      },
      remoteCandidates: jobData?.jobDetails?.remoteCandidates || "",
      willingToRelocate: jobData?.jobDetails?.willingToRelocate || "",
      salaryRange: {
        from: jobData?.jobDetails?.salaryRange?.from || "",
        to: jobData?.jobDetails?.salaryRange?.to || "",
      },
      specialties: jobData?.jobDetails?.specialties || {}, 
      experienceYears: jobData?.jobDetails?.experienceYears || {},
      totalYearsExperience: jobData?.jobDetails?.totalYearsExperience || "",
      requirements: {
        education: jobData?.requirements?.education || "",
        languages:
          jobData?.requirements?.languages?.length > 0
            ? jobData.requirements.languages.map((lang) => ({
              language: lang.language || "",
              level: lang.level || "",
            }))
            : [{ language: "", level: "" }],
      },
      description: {
        description: jobData?.description?.description || "",
        additionalInfo: jobData?.description?.additionalInfo || "",
      },
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        let res;
        const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
        if (profilePhotoName) {
          values.photo = profilePhotoName; // Set profile_photo_url to the photo name
        }
        if (id) {
          // Update existing job post
          res = await JobPostApi.updateJobPost(id, {
            jobDetails: {
              ...values,
            },
            requirements: values.requirements,
            description: values.description,
          });

          if (res.data.jobPost) {
            // Update the jobData state with the response data
            setJobData((prevState) => ({
              ...prevState,
              jobDetails: res.data.jobPost.jobDetails,
              requirements: res.data.jobPost.requirements,
              description: res.data.jobPost.description,
            }));
          }
        } else {
          // Create new job post
          res = await JobPostApi.createJobPost({
            jobDetails: values.positionName,
            requirements: values.location,
            description: values.employmentType,
          });
        }

        window.location.reload();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleClose = () => {
    setOpenPersonalInfo(false);
  };

  if (loading) {
    return (
      <div>
        <Loader loading={loading} />
      </div>
    );
  }

  if (!jobData) {
    return <Typography>No job post found.</Typography>;
  }

    
  const handleChipToggle = (name, checked) => {
    formik.setFieldValue(`jobDetails.specialties.${name}`, checked); // Correctly update Formik field value
    
    setSelectedCategories((prev) => {
        const newSelected = { ...prev };
        if (checked) {
            newSelected[name] = []; // If checked, add category with an empty subcategory list
        } else {
            delete newSelected[name]; // If unchecked, remove category
        }
        return newSelected;
    });
};

// Function to handle subcategory changes
const handleSubcategoryChange = (category, subcategory) => (event) => {
    const value = event.target.value;

    // Update Formik's state for subcategories dynamically
    formik.setFieldValue(`jobDetails.experienceYears.${category}.${subcategory}`, value);
    
    // Optionally update selected subcategories in state for UI updates
    setSelectedCategories((prev) => ({
        ...prev,
        [category]: {
            ...prev[category],
            [subcategory]: value, // Update the specific subcategory value
        },
    }));
};
  const handleLanguageChange = (index, key, value) => {
    const newLanguages = [...languages];
    newLanguages[index][key] = value;
    setLanguages(newLanguages);
    formik.setFieldValue(`requirements.languages[${index}].${key}`, value);
  };


  const handleAddLanguage = () => {
    const newLanguages = [
      ...formik.values.requirements.languages,
      { language: "", level: "" },
    ];
    formik.setFieldValue("requirements.languages", newLanguages);

    const newAddedLanguages = [...addedLanguages, true];
    setAddedLanguages(newAddedLanguages);
  };

  const handleRemoveLanguage = (index) => {
    const newLanguages = [...formik.values.requirements.languages];
    newLanguages.splice(index, 1);
    formik.setFieldValue("requirements.languages", newLanguages);

    const newAddedLanguages = [...addedLanguages];
    newAddedLanguages.splice(index, 1);
    setAddedLanguages(newAddedLanguages);
  };
 // Safely extract true specialties
 const trueTopSpecialities = Object.entries(formik.values.specialties || {})
 .filter(([_, value]) => value) // Keep only entries where value is true
 .map(([key]) => key) // Extract the key (specialty name)
 .join(', '); // Join the names into a single string


 const getCityColor = (city) => {
  return city === "Enter City" ? '#aaa' : 'black';
};
// Safely extract true specialties
const trueTopCity = formik.values.city
const formattedCity = trueTopCity
  ? trueTopCity // Add space between words
  : "Enter City"; // Default value when no true speciality

const cityColor = getCityColor(formattedCity);

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: "10px" }}>
        {isRecruiter && (
          <Button
            variant="text"
            onClick={() => navigate("/v1/recruiter/my-job-posts")}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "#F5F5F5",
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
            }}
          >
            <ArrowBackIcon
              sx={{
                mt: -0.5,
                mv: 1,
                ml: -2,
                backgroundColor: "#F5F5F5",
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            />

            <Typography
              component="h2"
              variant="body1"
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
              }}
            >
              Back
            </Typography>
          </Button>
        )}
        {isEmployee && (
          <Button
            variant="text"
            onClick={() => navigate("/v1/employee/job-offers")}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "#F5F5F5",
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
            }}
          >
            <ArrowBackIcon
              sx={{
                mt: -0.5,
                mv: 1,
                ml: -2,
                backgroundColor: "#F5F5F5",
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            />

            <Typography
              component="h2"
              variant="body1"
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
              }}
            >
              Back
            </Typography>
          </Button>
        )}
      </Grid>
      {isRecruiter && (
        <Grid container justifyContent="flex-end" xs={12}>
          <Button
            variant="text"
            onClick={() => setOpenPersonalInfo(true)}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "#F5F5F5",
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
              mt: -5,
            }}
          >
            <EditIcon
              sx={{
                backgroundColor: "#F5F5F5",
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            />
            <Typography
              component="h2"
              variant="body1"
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
              }}
            >
              Edit
            </Typography>
          </Button>
        </Grid>
      )}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box sx={{ position: "relative" }}>
          {/* Job Image */}
          
          <Box
  sx={{
    width: 100,
    height: 100,
    backgroundColor: jobData?.company_profile_photo_url ? "transparent" : "lightgray", // Gray background if no image
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: jobData?.company_profile_photo_url ? "transparent" : "white", // White text when no image
  }}
>
  {jobData?.company_profile_photo_url ? (
    <Box
      component="img"
      src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${jobData.company_profile_photo_url}`}
      alt="Company Profile Photo"
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        objectFit: "cover",
      }}
    />
  ) : (
    <Typography variant="body2">No image</Typography>
  )}
</Box>


          {/* Company Profile Photo (Overlayed) */}
          {/* <Box
            component="img"
            src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${jobData?.company_profile_photo_url}`}
            alt="Company Profile Photo"
            sx={{
              position: "absolute",
              top: 10, // Adjust positioning
              right: 10, // Adjust positioning
              width: 30, // Adjust size
              height: 30,
              borderRadius: "50%", // Circular image
              border: "2px solid white", // Optional border
            }}
          /> */}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
        <Typography
          sx={{ fontSize: { sm: "18px", md: "22px" } }}
          variant="h6"
        >
        {jobData.jobDetails?.positionName || "N/A"}
  {/* <Box component="span">
    {jobData.jobDetails?.specialties &&
    Object.keys(jobData.jobDetails.specialties).length > 0 ? (
      Object.keys(jobData.jobDetails.specialties)
        .filter((specialty) => jobData.jobDetails.specialties[specialty])
        .map((specialty, index) => (
          <Typography
          sx={{ fontSize: { sm: "18px", md: "22px" } }}
          variant="h6"
        >
        {specialty.replace(/([A-Z])/g, ' $1').trim()}   
          </Typography>
        ))
      
    ) : (
      <Typography component="span" variant="body1">
        N/A
      </Typography>
    )}
  </Box> */}
</Typography>
          <Typography variant="subtitle1" gutterBottom>
            {jobData.company}
          </Typography>
        </Box>

        {/* <Grid container justifyContent="flex-end" xs={12}>
          <Button
            variant="text"
            onClick={() => setOpenPersonalInfo(true)}
            sx={{
              backgroundColor: '#F5F5F5',
              color: '#F5F5F5',
              '&:hover': {
                backgroundColor: '#F5F5F5'
              }
            }}
          >
            <EditIcon
              sx={{
                mt: -18,
                ml: -2,
                backgroundColor: '#F5F5F5',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: '#F5F5F5'
                }
              }}
            />
            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', mt: -18, fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
              Edit
            </Typography>
          </Button>
        </Grid> */}
      </Box>
      <Dialog width="md" open={openPersonalInfo} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
            Edit Job Details
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ width: "90%", color: "text.secondary", mb: 1, ml: 3 }} />
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {/* <Grid item>
                <Box
                  sx={{
                    width: "100px",
                    height: "100px",
                    border: "1px dotted black",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="profile-photo-upload"
                    onChange={handleProfilePhotoChange}
                  />
                  <label htmlFor="profile-photo-upload">
                    <Button component="span">
                      {profilePhotoPreview ? (
                        <UploadImage
                          imageUrl={profilePhotoPreview}
                          altText="Profile Photo"
                        />
                      ) : jobData.jobDetails.photo ? (
                        <UploadImage
                          imageUrl={
                            profilePhotoPreview ||
                            `${process.env.REACT_APP_API_BASE_URL}/public/image/${formik.values.photo}`
                          }
                          altText={formik.values.photo}
                        />
                      ) : (
                        <AddIcon sx={{ fontSize: 30, color: "black" }} />
                      )}
                    </Button>
                  </label>
                </Box>
              </Grid> */}
              {/* <Grid item>
                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                  Company Profile Photo
                </Typography>
                <Typography
                  variant="body2"

                  sx={{
                    mr: 0,
                    textDecoration: "none",
                    color: "gray",
                    fontSize: "11px",
                  }}
                >
                  Maximum file size is up to 10MB
                </Typography>
              </Grid> */}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                  }}
                >
                  Position Name *
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder="Enter Position Name"
                  name="positionName"
                  value={formik.values.positionName}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.positionName &&
                    Boolean(formik.errors.positionName)
                  }
                  helperText={
                    formik.touched.positionName && formik.errors.positionName
                  }
                />
              </Grid>
                <Grid item xs={12}>
                <ExperienceForm
                    formik={formik}
                    placeHolder={trueTopSpecialities}
                    specialties={formik.values.specialties}
                    experienceYears={formik.values.experienceYears}
                    selectedCategories={selectedCategories}
                    experienceList={experienceList}
                    handleChipClick={handleChipClick}
                    handleSpecialtyChange={handleSpecialtyChange}
                    selectedSpecialty={selectedSpecialty}
                />
                </Grid>
{/*               
              <Grid item xs={12} sm={6}>
                <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                  Position name *
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    placeholder="Enter Position Name"
                    name="positionName"
                    value={formik.values.positionName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.positionName &&
                      Boolean(formik.errors.positionName)
                    } displayEmpty
                    renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select Position Name</span>}
                  >

                    {specialtiesList.map((design) => (
                      <MenuItem key={design} value={design}>
                        {design}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.positionName && formik.errors.positionName && (
                    <Typography variant="caption" color="error">
                      {formik.errors.positionName}
                    </Typography>
                  )}
                </FormControl>
              </Grid> */}
              
              <Grid item xs={12}>
                    <Box mt={2}>
                      <InputLabel
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "text.primary",
                          marginTop: "10px",
                        }}
                      >
                        Total Years Of Experience?
                      </InputLabel>
                      <FormControl fullWidth>
                        <Select
                          name="totalYearsExperience"
                          value={formik.values.totalYearsExperience}
                          onChange={formik.handleChange}
                          displayEmpty
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <span style={{ color: "#aaa" }}>
                                  Select Total Experience
                                </span>
                              );
                            }
                            return selected;
                          }}
                        >
                          <MenuItem value="" disabled>
                            <em>Select Total Experience</em>
                          </MenuItem>
                          {experienceList.map((experience) => (
                            <MenuItem key={experience} value={experience}>
                              {experience}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.totalYearsExperience &&
                          formik.errors.totalYearsExperience && (
                            <Typography variant="body2" color="error">
                              {formik.errors.totalYearsExperience}
                            </Typography>
                          )}
                      </FormControl>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel
                          mt={2}
                          sx={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "text.primary",
                            mb: 0.5,
                          }}
                        >
                          Education *
                        </InputLabel>
                        <FormControl fullWidth>
                          <Select
                            name="requirements.education"
                            value={formik.values.requirements.education}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            displayEmpty
                            error={
                              formik.touched.requirements?.education &&
                              Boolean(formik.errors.requirements?.education)
                            }
                          >
                            <MenuItem value="" disabled>
                              <em>Degree</em>
                            </MenuItem>
                            <MenuItem value="bachelor">
                              Bachelor's Degree
                            </MenuItem>
                            <MenuItem value="master">Master's Degree</MenuItem>
                            <MenuItem value="doctorate">
                              Doctorate Degree
                            </MenuItem>
                          </Select>
                          {formik.touched.requirements?.education &&
                            formik.errors.requirements?.education ? (
                            <Typography variant="body2" color="error">
                              {formik.errors.requirements.education}
                            </Typography>
                          ) : null}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Box mt={2}>
                          <Grid container spacing={2}>
                            {formik.values.requirements.languages.map(
                              (language, index) => (
                                <Grid item xs={12} key={index}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                      <InputLabel
                                        mt={2}
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "14px",
                                          color: "text.primary",
                                          mb: 0.5,
                                        }}
                                      >
                                        Language *
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          name={`requirements.languages[${index}].language`}
                                          value={language.language}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          displayEmpty
                                          error={
                                            formik.touched.requirements
                                              ?.languages?.[index]?.language &&
                                            Boolean(
                                              formik.touched.requirements
                                                ?.languages?.[index]?.language
                                            )
                                          }
                                        >
                                          <MenuItem value="" disabled>
                                            <em>Select Language</em>
                                          </MenuItem>
                                          <MenuItem value="English">
                                            English
                                          </MenuItem>
                                          <MenuItem value="Spanish">
                                            Spanish
                                          </MenuItem>
                                          <MenuItem value="French">
                                            French
                                          </MenuItem>
                                          <MenuItem value="German">
                                            German
                                          </MenuItem>
                                          <MenuItem value="Italian">
                                            Italian
                                          </MenuItem>
                                          <MenuItem value="Portuguese">
                                            Portuguese
                                          </MenuItem>
                                          <MenuItem value="Mandarin Chinese">
                                            Mandarin Chinese
                                          </MenuItem>
                                          <MenuItem value="Japanese">
                                            Japanese
                                          </MenuItem>
                                          <MenuItem value="Russian">
                                            Russian
                                          </MenuItem>
                                          <MenuItem value="Arabic">
                                            Arabic
                                          </MenuItem>
                                        </Select>
                                        {formik.touched.requirements
                                          ?.languages?.[index]?.language &&
                                          formik.errors.requirements
                                            ?.languages?.[index]?.language && (
                                            <Typography
                                              variant="body2"
                                              color="error"
                                            >
                                              {
                                                formik.errors.requirements
                                                  .languages[index].language
                                              }
                                            </Typography>
                                          )}
                                      </FormControl>
                                      {/* <TextField
                                            placeholder="Select Language"
                                            variant="outlined"
                                            fullWidth
                                            name={`requirements.languages[${index}].language`}
                                            value={language.language}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{
                                              shrink: Boolean(language.language),
                                              style: {
                                                fontSize: '14px',
                                                color: language.language ? 'inherit' : 'gray'
                                              }
                                            }}
                                            sx={{ mb: 2 }}
                                          />
                                          {formik.touched.requirements?.languages?.[index]?.language && formik.errors.requirements?.languages?.[index]?.language && (
                                            <Typography variant="body2" color="error">
                                              {formik.errors.requirements.languages[index].language}
                                            </Typography>
                                          )} */}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <InputLabel
                                        mt={2}
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "14px",
                                          color: "text.primary",
                                          mb: 0.5,
                                        }}
                                      >
                                        Level *
                                      </InputLabel>
                                      <FormControl fullWidth>
                                        <Select
                                          name={`requirements.languages[${index}].level`}
                                          value={language.level}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          displayEmpty
                                          error={
                                            formik.touched.requirements
                                              ?.languages?.[index]?.level &&
                                            Boolean(
                                              formik.touched.requirements
                                                ?.languages?.[index]?.level
                                            )
                                          }
                                        >
                                          <MenuItem value="" disabled>
                                            <em>Select Level</em>
                                          </MenuItem>
                                          <MenuItem value="Beginner">
                                            Beginner
                                          </MenuItem>
                                          <MenuItem value="Intermediate">
                                            Intermediate
                                          </MenuItem>
                                          <MenuItem value="Advanced">
                                            Advanced
                                          </MenuItem>
                                          <MenuItem value="Native">
                                            Native
                                          </MenuItem>
                                        </Select>
                                        {formik.touched.requirements
                                          ?.languages?.[index]?.level &&
                                          formik.errors.requirements
                                            ?.languages?.[index]?.level && (
                                            <Typography
                                              variant="body2"
                                              color="error"
                                            >
                                              {
                                                formik.errors.requirements
                                                  .languages[index].level
                                              }
                                            </Typography>
                                          )}
                                      </FormControl>
                                      {/* <TextField
                                            placeholder="Select Level"
                                            variant="outlined"
                                            fullWidth
                                            name={`requirements.languages[${index}].level`}
                                            value={language.level}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{
                                              shrink: Boolean(language.level),
                                              style: {
                                                fontSize: '14px',
                                                color: language.level ? 'inherit' : 'gray'
                                              }
                                            }}
                                            sx={{ mb: 2 }}
                                          />
                                          {formik.touched.requirements?.languages?.[index]?.level && formik.errors.requirements?.languages?.[index]?.level && (
                                            <Typography variant="body2" color="error">
                                              {formik.errors.requirements.languages[index].level}
                                            </Typography>
                                          )} */}
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Button
                                        onClick={() =>
                                          handleRemoveLanguage(index)
                                        }
                                        variant="standard"
                                        sx={{
                                          mb: 2,
                                          backgroundColor: "secondary.main",
                                          color: "text.secondary",
                                          "&:hover": {
                                            backgroundColor: "secondary.main",
                                          },
                                        }}
                                      >
                                        Remove
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                            <Grid item xs={12}>
                              <Button
                                type="button"
                                onClick={handleAddLanguage}
                                variant="standard"
                                sx={{
                                  backgroundColor: "white",
                                  color: "primary.main",
                                  "&:hover": {
                                    backgroundColor: "white",
                                  },
                                }}
                              >
                                <AddIcon
                                  sx={{
                                    mt: -0.5,
                                    ml: -3,
                                    color: "primary.main",
                                  }}
                                />
                                <Typography
                                  component="span"
                                  variant="body1"
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    color: "primary.main",
                                    padding: "2px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  Add More
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel
                          mt={2}
                          sx={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "text.primary",
                          }}
                        >
                          Description
                        </InputLabel>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          placeholder="Role Overview"
                          name="description.description"
                          value={formik.values.description.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.description?.description &&
                            Boolean(formik.errors.description?.description)
                          }
                          helperText={
                            formik.touched.description?.description &&
                            formik.errors.description?.description
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel
                          mt={2}
                          sx={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "text.primary",
                          }}
                        >
                          Additional Information
                        </InputLabel>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          placeholder="Type here..."
                          name="description.additionalInfo"
                          value={formik.values.description.additionalInfo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.description?.additionalInfo &&
                            Boolean(formik.errors.description?.additionalInfo)
                          }
                          helperText={
                            formik.touched.description?.additionalInfo &&
                            formik.errors.description?.additionalInfo
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
              {/* <Grid item xs={12} sm={6}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                  }}
                >
                  Location *
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    name="location"
                    value={formik.values.location}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    displayEmpty
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                  >
                    <MenuItem value="" disabled>
                      <em>Location</em>
                    </MenuItem>
                    <MenuItem value="remote">Remote</MenuItem>
                    <MenuItem value="onsite">On-site</MenuItem>
                    <MenuItem value="hybrid">Hybrid</MenuItem>
                  </Select>
                  {formik.touched.location && formik.errors.location && (
                    <Typography variant="body2" color="error">
                      {formik.errors.location}
                    </Typography>
                  )}
                </FormControl>
              </Grid> */}
           
<Grid item xs={12} sm={12}>
  <InputLabel
    mt={2}
    sx={{
      fontWeight: "600",
      fontSize: "14px",
      color: "text.primary",
    }}
    shrink={false}
  >
    Location *
  </InputLabel>
  <FormControl fullWidth>
    <Select
      name="location"
      value={formik.values.location}
      onChange={handleLocationChange} // Use custom change handler
      onBlur={formik.handleBlur}
      displayEmpty
      renderValue={(selected) => {
        if (selected === "") {
          return <span style={{ color: "#aaa" }}>Select Location</span>;
        }
        return selected;
      }}
      error={
        formik.touched?.location && Boolean(formik.errors?.location)
      }
    >
      <MenuItem value="" disabled>
        <em>Select Location</em>
      </MenuItem>
      <MenuItem value="remote">Remote</MenuItem>
      <MenuItem value="onsite">On-site</MenuItem>
      <MenuItem value="hybrid">Hybrid</MenuItem>
    </Select>
    {formik.touched.location && formik.errors.location && (
      <Typography variant="body2" color="error">
        {formik.errors.location}
      </Typography>
    )}
  </FormControl>

  {/* Conditionally render DynamicAutocomplete based on location */}
  {formik.values.location === "onsite" || formik.values.location === "hybrid" ? (
    <>
      <InputLabel
        mt={2}
        sx={{
          fontWeight: "600",
          fontSize: "14px",
          color: "text.primary",
          ml: 0.3,
          mt: 1,
        }}
        shrink={false}
      >
        Select City
      </InputLabel>
      <DynamicAutocomplete
        color={cityColor}
        placeHolder={formattedCity}
        formik={formik}
        fieldName="city" // Changed to "city" to avoid confusion with location
        value={formik.values.city} // Adjusted to reflect a city field
        // Uncomment fetchOptions to load city options dynamically
        // fetchOptions={fetchCities}
      />
    </>
  ) : null}
</Grid>

              <Grid item xs={12}>
                <InputLabel
                  mt={3}
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "text.primary",
                  }}
                >
                  Employment Type
                </InputLabel>
                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="employmentType.partTime"
                        checked={formik.values.employmentType.partTime}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "secondary.main" },
                        }}
                      />
                    }
                    label="Part Time"
                    sx={{ mr: 5, mb: 1 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="employmentType.fullTime"
                        checked={formik.values.employmentType.fullTime}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "secondary.main" },
                        }}
                      />
                    }
                    label="Full Time"
                    sx={{ mr: 5, mb: 1 }}
                  />
                </Box>
                {formik.touched.employmentType &&
                  formik.errors.employmentType && (
                    <Typography variant="body2" color="error">
                      {formik.errors.employmentType}
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "text.primary",
                  }}
                >
                  Would you consider remote candidates?
                </InputLabel>
                <FormControl
                  component="fieldset"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <RadioGroup
                    aria-label="remoteCandidates"
                    name="remoteCandidates"
                    value={formik.values.remoteCandidates}
                    onChange={handleChange}
                    sx={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label="Yes"
                      sx={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label="No"
                      sx={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value="Remote only"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label="Remote only"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "text.primary",
                  }}
                >
                  Would you consider candidates willing to relocate?
                </InputLabel>
                <FormControl
                  component="fieldset"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <RadioGroup
                    aria-label="willingToRelocate"
                    name="willingToRelocate"
                    value={formik.values.willingToRelocate}
                    onChange={formik.handleChange}
                    sx={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label="Yes"
                      sx={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label="No"
                      sx={{ marginRight: "20px" }}
                    />
                  </RadioGroup>
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      Specify the salary range *
                    </InputLabel>
                    <TextField
                    type="number"
                      fullWidth
                      placeholder="From"
                      name="salaryRange.from"
                      value={formik.values.salaryRange.from}
                      onChange={handleInputSalaryChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.salaryRange?.from &&
                        Boolean(formik.errors.salaryRange?.from)
                      }
                      helperText={
                        formik.touched.salaryRange?.from &&
                        formik.errors.salaryRange?.from
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      To *
                    </InputLabel>
                    <TextField
                    type="number"
                      fullWidth
                      placeholder="To"
                      name="salaryRange.to"
                      value={formik.values.salaryRange.to}
                      onChange={handleInputSalaryChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.salaryRange?.to &&
                        Boolean(formik.errors.salaryRange?.to)
                      }
                      helperText={
                        formik.touched.salaryRange?.to &&
                        formik.errors.salaryRange?.to
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                 
               
                

                </Grid>
              </Grid>
            </Grid>
            <DialogActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: 2,
                  mb: 1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!formik.isValid}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Save
                  </Typography>
                </Button>
              </Box>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Divider sx={{ width: "100%", color: "text.secondary", mt: 1, mb: 1 }} />
      <Typography
        variant="h6"
        component="h3"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Job Details:
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Position Name:</strong> {jobData.jobDetails?.positionName || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Location:</strong> {jobData.jobDetails?.location || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>City:</strong> {jobData.jobDetails?.city || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Employment Type:</strong>{" "}
        {jobData.jobDetails?.employmentType?.fullTime
          ? "Full-Time"
          : jobData.jobDetails?.employmentType?.partTime
            ? "Part-Time"
            : "Not Specified"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Remote Candidates:</strong>{" "}
        {jobData.jobDetails?.remoteCandidates || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Willing to Relocate:</strong>{" "}
        {formik.values.willingToRelocate || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Salary Range:</strong> €
        {jobData.jobDetails?.salaryRange?.from || "N/A"} -{" "}
        {jobData.jobDetails?.salaryRange?.to || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
  <strong>Profession: </strong>
  <Box component="span">
    {jobData.jobDetails?.specialties &&
    Object.keys(jobData.jobDetails.specialties).length > 0 ? (
      Object.keys(jobData.jobDetails.specialties)
        .filter((specialty) => jobData.jobDetails.specialties[specialty]) // Filter only true specialties
        .map((specialty, index) => (
          <Typography
            key={index}
            component="span"
            variant="body1"
            sx={{ display: "block", mb: 1 }}
          >
            {specialty.replace(/([A-Z])/g, ' $1').trim()} {/* Format specialty names */}
          </Typography>
        ))
    ) : (
      <Typography component="span" variant="body1">
        N/A
      </Typography>
    )}
  </Box>
</Typography>


      {/* <Typography variant="body1" paragraph>
        <strong>Experience Years:</strong>
        <Box component="span">
          {jobData.jobDetails?.experienceYears
            ? Object.entries(jobData.jobDetails.experienceYears).flatMap(
              ([specialty, yearsOrSubcategories]) => {
                // Check if yearsOrSubcategories is an object (representing subcategories)
                if (
                  typeof yearsOrSubcategories === "object" &&
                  yearsOrSubcategories !== null
                ) {
                  return Object.entries(yearsOrSubcategories[0]) // Accessing the first object in the array

                    .filter(([subSpecialty, years]) => years !== "")
                    .map(([subSpecialty, years]) => (
                      <Typography
                        key={subSpecialty}
                        variant="body2"
                        paragraph
                        sx={{ display: "inline", mr: 1 }}
                      >
                        <br /> <strong>{subSpecialty}:</strong> {years}
                      </Typography>
                    ));
                } else if (yearsOrSubcategories !== "") {
                  // If it's a direct value, render it
                  return (
                    <Typography
                      key={specialty}
                      variant="body2"
                      paragraph
                      sx={{ display: "inline", mr: 1 }}
                    >
                      <br /> <strong>{specialty}:</strong>{" "}
                      {yearsOrSubcategories}
                    </Typography>
                  );
                }
                return []; // Return an empty array if the value is empty
              }
            )
            : "N/A"}
        </Box>
      </Typography> */}
    <Typography variant="body1" paragraph>
  <strong>Specialites:</strong>
  <Box component="span">
    {jobData.jobDetails.experienceYears &&
      jobData.jobDetails.specialties &&
      Object.entries(jobData.jobDetails.experienceYears).some(
        ([specialty]) =>
          jobData.jobDetails.specialties[specialty] && // Only show specialties that are currently selected
          (typeof jobData.jobDetails.experienceYears[specialty] === "object" &&
            jobData.jobDetails.experienceYears[specialty] !== null &&
            Object.values(jobData.jobDetails.experienceYears[specialty]).some(years => years !== false))
      ) ? (
      Object.entries(jobData.jobDetails.experienceYears)
        .filter(([specialty]) => jobData.jobDetails.specialties[specialty]) // Filter out deselected specialties
        .flatMap(([specialty, yearsOrSubcategories]) => {
          if (typeof yearsOrSubcategories === "object" && yearsOrSubcategories !== null) {
            return Object.entries(yearsOrSubcategories).filter(
              ([subSpecialty, years]) => years // Only include subSpecialties with a true value
            ).map(([subSpecialty, years]) => (
              <Typography key={subSpecialty} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
                <br /> {subSpecialty}
              </Typography>
            ));
          } else if (yearsOrSubcategories) {
            return (
              <Typography key={specialty} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
                <br /> <strong>{specialty}</strong> 
              </Typography>
            );
          }
          return [];
        })
    ) : (
      "N/A"
    )}
  </Box>
</Typography>

      <Typography variant="body1" paragraph>
        <strong>Total Years Experience:</strong>{" "}
        {jobData.jobDetails?.totalYearsExperience || "N/A"}
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Job Requirements:
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Education:</strong> {jobData.requirements?.education || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Languages:</strong>{" "}
        {jobData?.requirements?.languages?.length
          ? jobData.requirements.languages
            .map((lang) => `${lang.language} (${lang.level})`)
            .join(", ")
          : "N/A"}
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Job Description:
      </Typography>
      <Typography variant="body1" paragraph>
        {jobData.description?.description || "Job Description is not available"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Additional Info:</strong>{" "}
        {jobData.description?.additionalInfo || "N/A"}
      </Typography>
    </>
  );
};

export default JobDetail;
