import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Link,
  InputLabel,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadImage from "../GlobalComponent/UploadImage";

const CandidateProfileModal = ({ open, onClose, candidate }) => {

  
  return (
    <Modal open={open} onClose={onClose}>
    <Box
  sx={{
    position: "relative",
    padding: 4,
    bgcolor: "white",
    borderRadius: 2,
    maxWidth: 600,
    mx: "auto",
    mt: 5,
    overflowY: "auto", // Enable scrolling
    maxHeight: "80vh", // Limit modal height
    // Custom scrollbar styling
    "&::-webkit-scrollbar": {
      width: "8px", // Width of the scrollbar
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1", // Track background color
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Thumb color
      borderRadius: "10px",
      border: "2px solid transparent", // Optional space around thumb
      backgroundClip: "padding-box", // Prevent the border from being clipped
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555", // Darker color when hovered
    },
    scrollbarWidth: "thin", // Firefox scrollbar width
    scrollbarColor: "#888 #f1f1f1", // Thumb and track colors for Firefox
  }}
>
  

        {/* Close button in the top-right corner */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Profile Picture */}
        <Box
          sx={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "15px",
            mx: "auto",
          }}
        >
          {candidate?.profile?.profile_photo_url ? (
            <UploadImage
            imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${candidate?.profile?.profile_photo_url}`}
            altText={candidate?.profile?.profile_photo_url}
      
            />
          ) : (
            <Typography>No Image</Typography>
          )}
        </Box>

        {/* Profile Details */}

        <Typography variant="h6">
          {candidate?.profile?.full_name || "N/A"}
        </Typography>
        <Typography variant="body1">
        Phone Number: {candidate?.profile?.phone_number || "N/A"}
        </Typography>
        <Typography variant="body1">
          City: {candidate?.profile?.current_city || "N/A"}
        </Typography>
        {/* Professional Details */}
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" sx={{ mt: 2, fontWeight: "600" }}>
          Qualification: 
        </Typography>
        {/* <Typography variant="body1" sx={{ mt: 1 }}>
          Professional Area: {candidate?.profile?.professional_area || "N/A"}
        </Typography> */}
              <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
  <Box component="span">
  {candidate?.profile?.top_specialities ? (
  (() => {
    // Directly use `top_specialities` as an object if it's not a string
    const specialties = candidate.profile.top_specialities;
    
    if (typeof specialties === 'object' && specialties !== null) {
      const activeSpecialties = Object.keys(specialties).filter(specialty => specialties[specialty]);

      return activeSpecialties.length > 0 ? (
        activeSpecialties.map((specialty) => (
          <Typography
            key={specialty}  // Use specialty name as the unique key
            sx={{ fontSize: "16px", ml: 0.41, color: "black" }}
            variant="h6"
          >
            {specialty.replace(/([A-Z])/g, ' $1').trim()} {/* Format specialty names */}
          </Typography>
        ))
      ) : (
        <Typography component="span" variant="body1" sx={{ml:1}}>
          N/A
        </Typography>
      );
    } else {
      return (
        <Typography component="span" variant="body1" sx={{ml:1}}>
          N/A
        </Typography>
      );
    }
  })()
) : (
  <Typography component="span" variant="body1" sx={{ml:1}}>
    N/A
  </Typography>
)}


  </Box>
</Typography>

        <Typography variant="body1" sx={{ mt: 1 }}>
          Total Years of Experience:{" "}
          {candidate?.profile?.total_years_experience || "N/A"}
        </Typography>

        {/* Job Preferences */}

        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            Job Preferences
          </Typography>

          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              ml: -0.1,
            }}
          >
            Type of Employment
          </InputLabel>

          <Box mt={2}>
            {candidate?.profile?.employment_type ? (
              <Typography
                ml={1}
                sx={{
                  fontSize: "14px",
                  color: "text.primary",
                  mt: -1,
                  ml: -0.1,
                }}
              >
                {JSON.parse(candidate?.profile.employment_type)["fullTime"]
                  ? "Full Time"
                  : ""}
                {JSON.parse(candidate?.profile?.employment_type)["fullTime"] &&
                JSON.parse(candidate?.profile.employment_type)["partTime"]
                  ? ", "
                  : ""}
                {JSON.parse(candidate?.profile.employment_type)["partTime"]
                  ? "Part Time"
                  : ""}
              </Typography>
            ) : (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary" }}
              >
                N/A
              </Typography>
            )}
            <InputLabel
              mt={2}
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                color: "text.primary",
                ml: -0.1,
              }}
            >
              Job Search Status
            </InputLabel>
            <InputLabel
              mt={2}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.1 }}
            >
              {candidate?.profile?.job_search_status || "N/A"}
            </InputLabel>
          </Box>
        </Box>
        {/* Location */}
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
              ml: -1,
            }}
          >
            Location
          </Typography>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              ml: -0.5,
            }}
          >
            Remote Work
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
          >
            {candidate?.profile?.remote_work ? "Yes" : "No"}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              ml: -0.5,
            }}
          >
            Relocation
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
          >
            {candidate?.profile?.relocation ? "Yes" : "No"}
          </InputLabel>
        </Box>
        {/* Salary */}
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            Salary Expectation
          </Typography>
          <Box mt={2}>
            <InputLabel
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                color: "text.primary",
                ml: -0.5,
              }}
            >
              Your Preferred Net Salary
            </InputLabel>
            {candidate?.profile?.salary_range ? (
              <Typography
                ml={1}
                sx={{
                  fontSize: "14px",
                  color: "text.primary",
                  ml: -0.3,
                }}
              >
                <strong> From:</strong>{" "}
                {
                  // only parse if it's a string
                  typeof candidate?.profile?.salary_range === "string"
                    ? JSON.parse(candidate?.profile?.salary_range).from
                    : candidate?.profile?.salary_range.from
                }{" "}
                <strong> To:</strong>
                {
                  // only parse if it's a string
                  typeof candidate?.profile?.salary_range === "string"
                    ? JSON.parse(candidate?.profile?.salary_range).to
                    : candidate?.profile?.salary_range.to
                }
              </Typography>
            ) : (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary" }}
              >
                N/A
              </Typography>
            )}
          </Box>
        </Box>
        {/* Link */}
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            Links
          </Typography>
          <Typography
            ml={1}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
          >
            {candidate?.profile?.linkedin_url || "N/A"}
          </Typography>
          <Typography
            ml={1}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
          >
            {candidate?.profile?.personal_blog_url || "N/A"}
          </Typography>
          {/* Add Link Details */}
        </Box>
        {/* Work Experience */}
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            Work Experience
          </Typography>
          {candidate?.profile?.work_experience ? (
            (() => {
              function multiParse(input) {
                let result = input;
                try {
                  while (typeof result === "string") {
                    result = JSON.parse(result);
                  }
                } catch (e) {
                  console.error("Error parsing input:", e);
                }
                return result;
              }

              const parsedWorkExperience = multiParse(
                JSON.parse(JSON.stringify(candidate?.profile?.work_experience))
              );
              const allCompanies = parsedWorkExperience
                .map((exp) => exp.company_name || "N/A")
                .join(", ");
              const allJobTitles = parsedWorkExperience
                .map((exp) => exp.job_title || "N/A")
                .join(", ");
              const allStartDates = parsedWorkExperience
                .map((exp) => exp.start || "N/A")
                .join(", ");
              const allEndDates = parsedWorkExperience
                .map((exp) => exp.end || "N/A")
                .join(", ");
              const allDescriptions = parsedWorkExperience
                .map((exp) => exp.description || "N/A")
                .join(", ");
              return (
                <Box mt={2}>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Company Name:</strong> {allCompanies}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Job Title:</strong> {allJobTitles}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Start Date:</strong> {allStartDates}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>End Date:</strong> {allEndDates}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Description:</strong> {allDescriptions}
                  </Typography>
                </Box>
              );
            })()
          ) : (
            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
              N/A
            </Typography>
          )}
        </Box>

        {/* Education */}
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            Education
          </Typography>
          {candidate?.profile?.education ? (
            (() => {
              function multiParse(input) {
                let result = input;
                try {
                  while (typeof result === "string") {
                    result = JSON.parse(result);
                  }
                } catch (e) {
                  console.error("Error parsing input:", e);
                }
                return result;
              }
              const parsedEducation = multiParse(
                JSON.stringify(candidate?.profile?.education)
              );
              const allUniversities = parsedEducation
                .map((edu) => edu.university_or_collage || "N/A")
                .join(", ");
              const allGraduationYears = parsedEducation
                .map((edu) => edu.graduation_year || "N/A")
                .join(", ");
              const allFieldsOfStudy = parsedEducation
                .map((edu) => edu.field_of_study || "N/A")
                .join(", ");
              const allDegrees = parsedEducation
                .map((edu) => edu.degree || "N/A")
                .join(", ");
              return (
                <Box mt={2}>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>University or College:</strong> {allUniversities}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Graduation Year:</strong> {allGraduationYears}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Field of Study:</strong> {allFieldsOfStudy}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Degree:</strong> {allDegrees}
                  </Typography>
                </Box>
              );
            })()
          ) : (
            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
              N/A
            </Typography>
          )}
        </Box>
        {/* Language */}
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            Language
          </Typography>
          {candidate?.profile?.languages ? (
            (() => {
              function multiParse(input) {
                let result = input;
                try {
                  while (typeof result === "string") {
                    result = JSON.parse(result);
                  }
                } catch (e) {
                  console.error("Error parsing input:", e);
                }
                return result;
              }
              const parsedLanguages = multiParse(
                JSON.stringify(candidate?.profile?.languages)
              );
              const allLanguages = parsedLanguages
                .map((language) => language.language || "N/A")
                .join(", ");
              const allLevels = parsedLanguages
                .map((language) => language.language_level || "N/A")
                .join(", ");
              return (
                <Box mt={2}>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Languages:</strong> {allLanguages}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      color: "text.primary",
                      ml: -0.5,
                    }}
                  >
                    <strong>Levels:</strong> {allLevels}
                  </Typography>
                </Box>
              );
            })()
          ) : (
            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
              N/A
            </Typography>
          )}
        </Box>
        {/* Additional Information */}
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            Additional Information
          </Typography>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
            }}
          >
            Objective
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
            {candidate?.profile?.objective || "N/A"}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
            }}
          >
            Achievements
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
            {candidate?.profile?.achievements || "N/A"}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
            }}
          >
            Personal Characteristics
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
            {candidate?.profile?.personal_characteristics || "N/A"}
          </InputLabel>
        </Box>

        {/* Close button */}
        <Button onClick={onClose} sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default CandidateProfileModal;
